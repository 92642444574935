const uiConfig = {
  info: {
    languages: [
      { code: "en", langCode: "en", title: "English", imgPath: "" },
      { code: "es", langCode: "es-es", title: "ESPAÑOL", imgPath: "" },
      { code: "ru", langCode: "ru-ru", title: "РУССКИЙ", imgPath: "" },
      { code: "ch", langCode: "de-de", title: "DEUTSCH", imgPath: "" },
      { code: "fr", langCode: "fr-fr", title: "Français", imgPath: "" },
      { code: "sa", langCode: "ar-sa", title: "عربي", imgPath: "" },
      { code: "ir", langCode: "fa-ir", title: "فارسی", imgPath: "" },
      { code: "kr", langCode: "ko-kr", title: "한글", imgPath: "" },
    ],
  },
  defaultLangCode: "en",
  isCash: false,
  isSoundEnabled: false,
  isInvitationEnabled: true,
  loginPage: {
    isSkipLoginVideo: false,
  },
  grabOrderPage: {
    isAccountBalanceFirst: false,
    progressNotes: {
      progressNote2: "",
      progressNote3: "",
      progressNote4: "",
    },
  },
  bindWalletSettings: {
    isUSDTWallet: true,
  },
  bindBankAccSettings: {
    isShowBankCode: false,
  },
};
window.uiConfig = uiConfig;
